




























// TODO: Trans
import Vue from "vue";
import { Product } from "@/core/models";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class PricingTier extends Vue {
  @Prop({ default: () => 0 }) i!: number;
  @Prop({ default: () => null }) item!: Product;
}
